@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');

* {
    margin:0;
    padding:0;
    font-size:14px;
}

body {
    width:100%;
    background:#f7f6f6;
    overflow-x: hidden;
    font-family: 'Roboto', sans-serif;
    font-size:14px;
}
body .custom-calender input {
    width: calc(100% - 34px)!important;
}
body .custom-calender {
    display: block!important;
    width: 100%!important;
}
.custom-center {
    text-align:center;
}
.col-fifth {
    width:calc(100% / 5)
}
.popup-modal {
    width: 50%;
}
.button-color{
 background-color: #003e7e;

}
.login-wraper {
    background: #eee;
    height: 100vh;
}
.logout-wraper{
    background: #eee;
    height: 121vh;

}
.customModal {
    position: absolute;
    z-index: 99;
    width: 60%;
    top: 30%;
}
.block {
    margin-top: 22px;
    background: #fff;
    padding: 28px;
    border-radius: 14px;
    border: 4px dashed #f9832a;
}
.block label {
    font-weight: normal;
}
.product-pic {
    width:80%;
}
.block h2 {
    color: #6c2e2f;
    position: relative;
}
.redeem-form {
    display: table;
    margin:30px auto 0;
}
/* .pa-bg {
    background: url('./assets/images/bg.jpg') no-repeat;
    background-size: cover;
    height: 100vh;
    position: fixed;
    width: 100%;
    overflow-y: auto; */

.block h2::after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 50px;
    height: 2px;
    background: #f9832a;
    bottom: -7px;
}
.login-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
}

.tree-stucture {
    padding-left: 30px;
    margin-bottom: 8px;
    margin-top: 8px;
    list-style: none;
}
/* .row1 {
    margin-left:-15px;
    margin-right:-15px;
} */
.tree-stucture input {
    margin-right: 12px;
}
.login-logo {
  text-align: center;
  padding: 30px 0;
}
.logo-image{
    height: 25%;
    width: 25%; 
}
.logo-sidebar-image{
    height: 50%;
    width: 50%;
}
.white-box {
    background: #fff;
    padding: 12px;
}
body .p-datatable .p-datatable-tbody > tr > td {
    overflow: auto;
}
#react-sidebar .sidebar-backdrop.show {
    display: none!important;
}
.border-box {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0 12px;
    margin: 20px 0;
    box-shadow: 0 0 12px #ccc;
    background: #ffffff;
}
label span {
    color: red;
    padding-right: 4px;
}
.profile-frm input {
    margin-bottom:4px;
    padding:4px;
    border-radius:4px;
    border:1px solid #ccc;
}
.user-menu .nav-link a {
    color:#fff;
}
.box {
    background: #fff;
    padding: 12px;
    margin: 30px 0;
    border-radius: 4px;
}
.progress {
    height: 5px;
    margin-bottom: 7px;
}
.progress-bar {
    background-color: #29c352;
}
.box h3 {
    color: #f68430;
}
.box h4 {
    color: #13233b;
}
.form-wraper {
  background: #fff;
  padding: 32px;
  border-radius: 22px;
  text-align: center;
}

.login-container .form-group {
  position: relative;
}

.login-container .form-group span {
  position: absolute;
  left: 7px;
  top: 0px;
  font-size: 17px;
}
.toast-header {
    display: flex;
    align-items: end;
    padding: .25rem .75rem;
    color: #6c757d;
    background-color: transparent;
    background-clip: padding-box;
    border-bottom: none;
    text-align: right;
}
.form-control {font-size:14px;}
.login-container .form-control {
  border-radius: 0;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #ccc;
  padding-left: 28px;
}
.toast {
    background:#ff7d7d; color:#fff; padding:12px; border-radius:4px;
    position: absolute;
    top: 9px;
    right: 8px;
}
.login-container .form-wraper button[type="submit"] {
  width: 100%;
  border-radius: 22px;
  background: #13233b;
  border: none;
  padding: 10px;
  margin-bottom: 12px;
  color: #f98126;
}

.header-top {
  background: #fff;
  color: #797979;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.3);
  position: fixed;
  width: 100%;
  height: 78px;
  z-index: 9;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-section { 
    width: 335px;
    padding: 0;
    margin-top: 15px;
    display: flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }
  
  .logo-section img {
    cursor: default;
    max-width: 100%;
    max-height:100%;
    margin-bottom:0px;
  }

.user-menu {
  display: none;
  position: absolute;
  text-align: left;
  width: 200px;
  color: #fff;
  background: #13233b;
  right: 0;
  z-index: 9;
  margin-top: 16px;
}

.user-area .dropdown-toggle::after {
  display: inline-block;
  margin-right: 4px;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}

.left-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  margin-left: -100px;
  gap: 10px; 
}
.dropdown-label {
    flex-shrink: 0;
    padding-right: 10px;
    text-align: center; 
  }

.left-section p {
    margin: 0;
    font-size: 14px;
}

.right-section {
    display: inline-block;
    text-align: right;
    padding: 15px 15px 0 20px;
    margin-bottom: 0;
}
.right-section img {
  width: 40px;
  border-radius: 100%;
  margin: 0 10px;
}
.footer {
  background: #000;
  color: #fff;
  text-align: center;
  padding: 15px 0;
  font-size: 13px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.rounded-circle {
  border-radius: 50%!important;
}

/* You can add global styles to this file, and also import other style files */


.custom-calender .ui-calendar, .custom-calender .ui-calendar input {
    width: 100%;
}
.custom-calender .ui-calendar input {
    border: 1px solid #ced4da;
}
body .ui-table .ui-table-tbody > tr > td {
    padding: 2px 0.857em;
}
.btn {
    font-size:14px; 
    padding: .25rem .70rem;   
}
body .scroll-td .ui-table .ui-table-tbody > tr > td {
    overflow: hidden!important;
}
.btn-primary {
    color: #fff;
    background-color: #13233b;
    border-color: #13233b;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
    background-color: #191919!important;
    color: #f3c500!important;
}
.sidebars {
    background: #13233b;
    top: 0;
    position: relative;    
    height: calc(100vh - 124px);    
}
.p-datatable-emptymessage {text-align:center;}
/* .sidebar-wraper {
    width: 19%;
    height: calc(100vh - 127px);
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 79px;
    background: #13233b;
    padding: 0;
    z-index: 9;
}
*/
body #react-sidebar .sidebar-main {
    width: 172px;
    height: calc(100% - 128px);
    background-color: #13233b;
    top: 79px;
    left: 0;
    overflow-y: hidden;
    box-shadow: none;
    z-index: 9;
} 
body .sidebar-main-content ul li {
    color:#fff;
}
body .sidebar-main-content ul li:hover {
    background-color: transparent;
    color: #e0e0e0;
    letter-spacing: 2px;
    transition: all 0.3s;
}
body #react-sidebar .sidebar-main.second {
    position: fixed;
    top:0;
}
body #react-sidebar .second.show {
    top: 0;
}
body .p-panelmenu .p-panelmenu-header > a {
    border: none!important;
    background-color: transparent!important;
    color: #fff!important;
    font-weight: normal!important;
}
body .p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
    color: #fff!important;
    float: right!important;
}
body .p-panelmenu .p-panelmenu-header:not(.p-highlight) > a:hover .p-panelmenu-icon {
    color: #fff!important;
}
body .p-panelmenu .p-panelmenu-header.p-highlight > a {
    border: none!important;
    background-color: transparent!important;
    color: #ffffff;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
body .p-panelmenu .p-panelmenu-header > a:focus {
    box-shadow: none!important;
}
.error {
    color: red;
    padding-top: 4px;
}
.user-menu .nav-link {
    padding: 6px 12px;
    cursor:pointer;
}
.user-menu .nav-link .fa {
    padding-right: 8px;
}
.ui-panelmenu .ui-menuitem-text {padding-left:12px;}
.open .sidebar-wraper {
    overflow: visible;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a {
    border: 1px solid #f9842d !important;
    background-color: #f9842d !important;
    color: #ffffff !important;
}
.btn-primary:hover, .btn-primary.focus, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #13233b;
    border-color: #13233b;
    box-shadow: none;
}
.custom-mdrop, .custom-mdrop .ui-multiselect {
    display: block;
    width:100%;
    margin-bottom:12px;
}
.table-option .ui-dropdown  {
    width: 100%;
    border: 1px solid #ced4da;
}

.open .sidebar-wraper {
    width: 6%;
}
.open .sidebar-wraper .fa {
    font-size: 19px;
}
.open .left-panel {
    display: none;
}
.open .right-panel {
    width: 99%;
    margin-left: 15px;
}
.search-field {
    float:right;
    padding:4px 8px;
    border-radius:4px;
    border:1px solid #ccc;
    margin-top:20px;
}
.right-panel {
    margin-bottom: 50px;
    margin-top: 94px;
    padding-right: 30px;
    padding-left: 227px;
    width: 100%;
    display: inline-block;
}
.left-panel {
    width: 20%;
    display: inline-block;
}
.sidebar-wraper a {
    color: #fff;
    padding: 0;
    display: block;
}
.sidebar-wraper a .fa {
    margin-right: 12px;
}
#sidebar-parent {
    padding-top: 12px;
}
body .sidebar-body > ul {
    margin-bottom: 12px;
}
.right-panel.resize {
    max-width: 90%;
    flex: 0 0 90%;
}
.right-panel .heading h1 {
    font-weight: normal;
    font-size: 25px;
    color: #13233b;
    display: inline-block;
}
.heading {
    display: table;
    width: 100%;
    color: #003e7e;
}
.p-dialog-content label {
    font-weight: normal;
}
body .heading .p-button {
    float: right;
    margin-top: 0;
    margin-bottom: 13px!important;
}
.open .p-panelmenu-header .p-menuitem-text {
    font-size: 0;
}

 body .open .p-panelmenu .p-panelmenu-header > a {
    padding: 0.714em 0;
}
.open .p-toggleable-content {    
    position: absolute;
    right: -157%;
    margin-top: -30px;
    border-top: 1px solid #ccc;
}
.open .p-toggleable-content-enter-done .p-menuitem-text {
    font-size:14px;
}
.open .ui-panelmenu .ui-panelmenu-content-wrapper .ui-menuitem-text, .ui-panelmenu .ui-panelmenu-content-wrapper .ui-menuitem-text {
    font-size:13px;
}
.open .ui-panelmenu .ui-panelmenu-content-wrapper {
    overflow: hidden;
    position: absolute;
    right: -160%;
    z-index: 999;
}
.custom-drop2 .ui-dropdown {
    width: 100%;
    margin-bottom: 0;
    border: 1px solid #ced4da;
    min-width: 100%;
}
.custom-drop .ui-dropdown {
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #ced4da;
}
body .ui-panelmenu .ui-panelmenu-header > a {
    border: 1px solid #13233b !important;
    background-color: #13233b !important;
    color: #fff !important;
    font-weight: normal;
}
body .ui-panelmenu .ui-panelmenu-header:not(.ui-state-active) > a:hover .ui-panelmenu-icon, body .ui-panelmenu .ui-panelmenu-header > a .ui-panelmenu-icon {
    color: #f5e98e!important;
}
.user-form {
    overflow-x: hidden;
    width: 100%;
    margin: 0;
}
.user-form input[type="checkbox"] {
    margin-right: 9px;
    vertical-align: unset;
}
#left-panel .navbar {
    padding: 0;
}
body .ui-widget {
    font-size:13px;
}
.table thead th {
    font-weight: 500;
}
#left-panel {
    background: #e4e4e4;
}
#left-panel li {
    display: block;
    width: 100%;
    border-bottom: 1px solid #000;
}
#left-panel li:last-child {
    border:none;
}
#left-panel li a {
    padding: 12px;
    display: block;    
    color: #000;
    position: relative;
}
#left-panel li a:hover {
  background: #f8e794;
  text-decoration: none;
}

#left-panel li a i {
    padding-right: 12px;
}

#left-panel li .dropdown-toggle::after {
    right: 10px;
    position: absolute;
    top: 23px;
}
.navbar .navbar-nav li.menu-item-has-children .sub-menu {
    background: #353535;
    border: none;
    box-shadow: none;
    overflow-y: hidden;
    margin: 0;
    border-radius: 0;
    padding-left: 16px;
}
body .navbar .navbar-nav li.menu-item-has-children .sub-menu {
    max-height: 1000px;
    opacity: 1;
    position: static;
   
}
#left-panel li .sub-menu li {
    border:none;
}
#left-panel li .sub-menu li a {
  padding:4px 12px;
  color:#fff;
}
.ui-panelmenu .ui-panelmenu-icon {
    float:right;
}
.left-panel .dropdown-toggle::after {
    border:none;
}
.navbar .navbar-nav li.menu-item-has-children > a::before {
    content: "";
    position: absolute;
    top: 23px;
    right: 14px;
    width: 8px;
    height: 8px;
    border-style: solid;
    border-width: 1px;
    border-color: #000 #000 transparent transparent;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: all .25s ease;
}
.navbar .navbar-nav li.menu-item-has-children > ul > li.menu-item-has-children > a::before {
    border-color:#fff #fff transparent transparent!important;
    top: 12px;
}
body .ui-dialog .ui-dialog-titlebar {
    border: 1px solid #1f1f1f!important;
    background: #1f1f1f!important;
    color: #f3c500!important;
    box-shadow: 0 0 6px #f3c500;
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon:hover {
    color: #d6d6d6!important;
}
body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
    color: #d6d6d6!important;
}
.add-btn {
    position: absolute;
    right: 0;
    top: 5px;
}
.form-control, body .ui-inputtext  {
    height:auto;
    padding: 3px 8px;
    line-height:normal;
}
.saperator {
    position: relative;
    margin-top: 16px;
    margin-bottom: 20px;
    font-size: 19px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    border-top: 1px solid #ccc;
    padding-top: 10px;
}
.user-form .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #938c55;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(147, 140, 85,.25);
}
.navbar .navbar-nav li.menu-item-has-children > a.btn-toggle::before, .navbar .navbar-nav li.menu-item-has-children > ul > a.btn-toggle::before {
    -webkit-transform: rotate(133deg);
    transform: rotate(133deg);
}
body .ui-paginator {
    padding: 5px 0;
}

.user-area {
    padding-bottom: 18px;
    width: 335px;
    float: right;
}

.user-area:hover .user-menu {display:block;}
    
    .right-section .heading, .heading2 {
        border-bottom: 1px solid #000;
        padding-bottom: 15px;
        font-size: 15px;
        margin-bottom: 12px;
        font-weight: bold;
       
    }
    .right-section .heading2 {
        padding-bottom:0!important;
    }

  .user-form h4 {
        text-align: center;
        font-size: 20px;
        margin: 10px 0 20px;
    }
    .user-form input {
        margin-bottom:10px;
        font-size: 13px;
    }


@media (max-width:1024px) {
    .btn {
        margin-bottom: 10px;
    }
    .redeem-form .btn {margin-bottom:0;}
}

@media(max-width:768px) {
    .left-panel {
        display: none;
    }
    .right-panel {
        padding-right: 15px;
        padding-left: 31px;
        width: 100%;
    }
    .user-area {
        width:100%;
    }
    .open .right-panel {
        width: 100%;
    }
    .open .left-panel {
        width: 100%;
        display: block;
    }
    .open .sidebar-wraper {
        width: 100%;
    }
    .open .p-panelmenu-header .p-menuitem-text {
        font-size: 13px;
    }
    .open .p-toggleable-content {
        position: relative;
        right: 0;
        margin-top: 0;
    }
    
    .sidebar-wraper {        
        height: auto;
        overflow: hidden;
        position: relative;
        left: 0;
        top: 79px;
        background: #13233b;
        padding: 12px;
        z-index: 9;
        padding-left: 36px;
    }
    .popup-modal {
        width:65%;
    }
    
}
@media(max-width:580px) {
    .popup-modal {
        width:95%;
    }
    .box {
        margin:10px 0;
    }
    .redeem-form .btn {
        margin: 0 auto;
    }
    .redeem-form .form-group {
        width:100%;
    }
    .right-panel .heading h1 {
        font-size:20px;
    }
}
@media(max-width:480px) {
    .product-pic {
        width:100%;
    }
    .row1 {
        margin-left:0;
        margin-right:0;
    }
    .logo-section {
        width:100%;
        text-align: center;
    }
    .right-section {
        width: 100%;
        text-align: center;
        padding: 0px 15px 0 20px;
    }
    .right-panel {
        margin-top: 18px;
    }
    .header-top {
        position: relative;
        left: 0;
    }
    .sidebar-wraper {
        top:3px;
    }
    .footer {
        position: relative;
    }
    .right-panel {
        padding-right: 23px;
        padding-left: 23px;
    }
}

.tblAuto .p-datatable-wrapper > table
{
    border-collapse: collapse !important;
    width: 100% !important;
    table-layout: auto !important;
}

.tblAuto .p-datatable-thead > tr > th:last-child{
    width: 120px !important;
}

body .p-paginator .p-dropdown .p-dropdown-label, body .p-paginator .p-dropdown .p-dropdown-trigger {
    padding-bottom: 1px !important;
    color: #848484 !important;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    background-color: white;
  }

.datatable-crud-demo .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media screen and (max-width: 960px) {
    .datatable-crud-demo .table-header {
        align-items: flex-start;
    }
}
.datatable-crud-demo .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.datatable-crud-demo .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}
.datatable-crud-demo .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 960px) {
    .datatable-crud-demo .p-toolbar {
        flex-wrap: wrap;
    }
    .datatable-crud-demo .p-toolbar .p-button {
        margin-bottom: 0.25rem;
    }
    .datatable-crud-demo .table-header {
        flex-direction: column;
    }
    .datatable-crud-demo .table-header .p-input-icon-left, .datatable-crud-demo .table-header input {
        width: 100%;
    }
}
body {
    background: lightsalmon;
  }
  
  .forget-password .panel-default {
    padding: 31%;
    margin-top: -27%;
  }
  .forget-password .panel-body {
    padding: 15%;
    margin-bottom: -50%;
    background: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  img {
    margin-bottom: 5%;
  }
   .css-d9ml5t-MuiDrawer-docked .MuiDrawer-paper{
    background-color: #13233b;
   }

   p.field-wrapper input {
    float: right;
}
p.required-field label::after { 
    content: "*";
    color: red;
}
.required-field::before {

    content: "*";

    color: red;

  }
  .cert-link {
    font-size: 17px;
}
 
.uploaded-certs {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.dialogWidth {
width: 475px
}
.required {
    color: red;
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
    color: #696cff;
    display: none;
}

#cert-upload-label,
#test-cert-upload-label,
.filebox a {
    background: rgb(0, 62, 126);
    cursor: pointer;
    color: #ffffff;
    border: 1px solid #6366F1;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: normal;
}
.filebox td {
    padding: 10px;
}
.filebox h5 {
    margin: 0;
}
.close-upload {
    color: red;
    font-size: 25px;
    line-height: 25px;
    cursor: pointer;
}
.filebox a {
    text-decoration: none;
}

.dashboardApprover__title
{
    color: #003e7e;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.dashboardApprover__card
{
    color: #003e7e !important;
    background-color: transparent;
    text-align: center;
    margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;
    transition: background-color 0.1s ease;

}

.dashboardApprover__card:hover
{
    background-color: #6ab045;
}

.dashboardApprover__row1
{
    padding-bottom: 20px; 
    display: flex; 
    justify-content: space-between;
    text-align: center;
}

.dashboardApprover__itemsCard
{
    color: #003e7e !important;
    text-align: center;
    flex: 1 0 250px;
    margin-right: 12px;
    margin-left: 12px;
    margin-bottom: 20px;
}

.dashboardApprover__cardText
{
    font-weight: bold;
    font-size: 18px;
    text-align: center;
}

.dashboardApprover__row1 .dashboardApprover__cardText
{
    line-height: 1.5;
}
.foundOnline-container,
.isSupplierActive-container {
    margin: 12px 0 13px 2px;
    column-gap: 10px;
}
.foundOnline-container > div,
.isSupplierActive-container > div {
    display: flex;
    column-gap: 5px;
}

.deactivationComments {
    width: 300px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.detail-row {
    display: flex;
    column-gap: 1rem;
    flex-wrap: wrap;
}

.item-detail {
    width: calc((100% - 4rem) / 5);
    display: flex;
    flex-direction: column;
}
.notes-column {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
  /* ItemDetail.css */
.input-container {
    position: relative;
  }
  
  .input-link-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
  }

  .link-text {
    color: blue !important;
    text-decoration: underline;
    width: 100%;
    box-sizing: border-box;
    padding: 0.5em;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    overflow: auto;
  }
  
.supplierManagementDialog {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

.supplierManagementField {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}
.password-input-container {
    display: flex;
    align-items: center;
}
.conpassword-input-container {
    display: flex;
    align-items: center;
}

.password-input {
    flex: 1;
}

.password-toggle-button {
    margin-left: -35px; /* Adjust this value if needed */
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.multiSelectWrapChips .p-multiselect-label {
    display: flex;
    flex-wrap: wrap;
    row-gap: .25rem;
}
