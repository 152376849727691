.item-status-container {
  padding: 20px 0;
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
}

.item-status-header {
  color: #003e7e; 
  font-size: 15px; 
  font-weight: bold;
}

.item-status-text {
  color: #003e7e; 
  font-size: 4rem; 
  font-weight: 500;
  text-align: center;
}
.item-status-number {
  color: white;
  font-size: 4rem; /* Larger font size for the count */
  font-weight: 700;
  text-align: center;
}
.item-status-card {
  padding: 0.2rem;
  border-radius: 0.2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  text-align: center;
  border: 2px solid #888091;
  width: 15rem;
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow {
  color: #156082;
  margin-top: 26px;
}

.enricher {
  justify-content: space-between;
  flex-direction: column;
  padding: 10px 0.2rem;
  width: 16rem;
  height: 16rem;
}

.enricher .item-status-text {
  color: white;
  margin: 0;
}

.enricher .item-status-number {
  margin: 0;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.quality-item-status-container {
  padding: 20px 0;  
  display: grid;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  justify-items: center;
  grid-gap: 3rem;
  grid-template-columns: repeat(5, 1fr);
  grid-template-areas: 
  "a b c d ."
  "e f g h i"
  "j k l m n";
}

.esg-item-status-container {
  padding: 20px 0;  
  display: grid;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  justify-items: center;
  grid-gap: 3rem;
  grid-template-columns: repeat(5, 1fr);
}

